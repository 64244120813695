var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Drawer",
        {
          attrs: {
            closable: false,
            visible: _vm.visible,
            size: "normal",
            title: "导出" + (_vm.data.label ? _vm.data.label : ""),
          },
          on: { close: _vm.onClose, onSubmit: _vm.submit },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("ImportHeader", { attrs: { list: _vm.list } }),
              _c("ImportList", { on: { change: _vm.setHeader } }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }